import press from "./assets/picss.png";
import CleaningImage from "./assets/Icons/CleaningConvert.jpg";
import carPainter from "./assets/Icons/carPainter.jpg";
import deepCleaning from "./assets/Icons/deepCleaning.jpg";
import Electrician from "./assets/Icons/electrician.webp";
import Handyman from "./assets/Icons/handyman.jpg";
import paintWork from "./assets/Carousel/Cleaning1.jpg";
import plumbing from "./assets/Icons/plumbing.jpg";
import bannerImage from "./assets/contact.jpg";
import zaka from "./assets/zaka.jpg";
import Zulqer from "./assets/Zulqer.PNG";
import UsamaSam from "./assets/UsamaSam.PNG";
import Aqeel from "./assets/Aqeel.PNG";
import Asfand from "./assets/Asfand.PNG";
export const AboutUsData = [
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
];
export const WhyUsData = [
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
	{
		heading: "Appointment:",
		detail:
			"HomeRepairFix is a professional handyman team in Dubai offering various kinds of Home and Commercial product installations, assembly, maintenance and repair solutions. Our handymen cater to services of all sizes from small household work to bulk commercial projects. We also take up general inspection routinely for various infrastructural needs.",
	},
];
export const feedBackData = [
	{
		img: zaka,
		name: "zaka Rehman",
		detail:
			"This Company is very great. Employee of this company is very nice and hardworking.",
	},
	{
		img: UsamaSam,
		name: "Usama Sam",
		detail:
			"highly suggesting! sensitive group, and they fixed my electrics work very quickly. They took all the necessary steps being fixed to the Covid-19 1 safety measures and things done to keep off danger when going in, coming in my place. would get use of person for money them again if anything else needs putting right. Keep it up men, boys!",
	},
	{
		img: Zulqer,
		name: "Zulqer Nain Ali Abad",
		detail:
			"Very Impressive And HeardWorking Professionals in Dubai. And Their Work is Very Impressive. Keep It Up Boys. One day you will achieve very high position in dubai",
	},
	{
		img: Aqeel,
		name: "Aqeel",
		detail:
			"We had some urgent work on a Friday late evening - our bathroom faucet had a leak. They came to fix it at 7:00pm when two of the other refused. A good job and reasonable charges given the circumstances, recommended.",
	},
	{
		img: Asfand,
		name: "Asfand Yar Khan",
		detail:
			"The guys from HomeRepairFix really efficient and helpful, specially would resolve your service issue during appsfg. There was even an emergency trip was only one of company which I contacted and HomeRepairFix were among the 6 companies I tried to contact.",
	},
];
export const ServicesArray = [
	{
		name: "cleaning",
		img: CleaningImage,
		description:
			"With the increase in weather temperatures everywhere, the last thing you want to deal with is air conditioner repair and maintenance to keep it running. Not everyone is...",
		vat: "12",
		price: "112",
		detail: {
			bannerImage: bannerImage,
			upperLeftDetail: {
				heading: "HomeRepairFix, Dubai's best painting service!",
				details:
					"The outer layer of the walls tends to get worn out due to rain, dust, accidental handprints, children’s drawings, furniture marks, stains and whatnot. This reduces the overall look and brightness of the house. Hence, buildings often require time to time painting job done to keep their charm up. A lick of paint can do wonders to the entire building, whether its interiors or exteriors of the structure. But if you’re wondering where you can get expert-level wall painting services in Dubai- HomeRepairFix is the answer!",
			},
			upperRightDetail: {
				heading: "Book Painting Service",
				number: " 800 8289",
				details:
					"HomeRepairFix’ one of the many expertise is painting services which can be used for all your residential and commercial structures. They are currently the superior painting company in Dubai known majorly for handling different kinds of painting services for various types of infrastructures. With our skilled and efficient painters in Dubai, you can decorate or redecorate your homes, offices, or any other buildings you own. So get together with the HomeRepairFix painters and avail exquisite makeover to your building.",
			},
			midDetail: {
				heading:
					"Checkout HomeRepairFix Assorted Wall Painting Services in Dubai",
				video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
				details:
					"Our painting company has tremendously proficient painters ready to avail our various wall painting services along with other maintenance assistances. These include:",
			},
			lowerLeftDetail: {
				heading: "Interior decoration:",
				details:
					"We help you choose from a variety of paints to suit your interiors. Guiding our clients through some samples and offering them an idea of how their rooms will transform after painting, we ensure their content before going ahead with the actual paint job. Our painters also make sure that all your furniture, carpets and other items in the interior rooms of the building remain clean and undamaged.",
			},
			lowerCenterDetail: {
				heading: "Exterior painting:",
				details:
					"The outer look of the building is as important as inner. Hence, we cater to painting all kinds of walls (concrete, stone, brick or whatever), windows, doors, gates and such similar things. Our master painters will also advise you on the weather conditions and the paint that will perfectly suit the Dubai buildings. HomeRepairFix painters are always equipped with appropriate tools like ladders, ropes, paintbrushes and every other thing required to finish the job accurately.",
			},
			lowerRightDetail: {
				heading: "Maintenance works:",
				details:
					"Timely maintenance of the structures will make them look bright and also stand tall for decades. Hence, it’s not just the new building we work on. Both the new and old structures are tended to by our handyman crew equally. We offer house painting service, apartment painting services, commercial buildings and also villa painting services in Dubai among several other works. For the maintenance works of old buildings, we first patch up holes, cracks, and such breaks in the walls. Then we sand the wall properly to apply primer. Once this foundation has been laid, we move to avail our flawless wall painting services",
			},
		},
	},
	{
		name: "CarPenter",
		img: carPainter,
		description:
			"With the increase in weather temperatures everywhere, the last thing you want to deal with is air conditioner repair and maintenance to keep it running. Not everyone is...",
		vat: "14",
		price: "114",
		detail: {
			bannerImage: bannerImage,
			upperLeftDetail: {
				heading: "HomeRepairFix, Dubai's best painting service!",
				details:
					"The outer layer of the walls tends to get worn out due to rain, dust, accidental handprints, children’s drawings, furniture marks, stains and whatnot. This reduces the overall look and brightness of the house. Hence, buildings often require time to time painting job done to keep their charm up. A lick of paint can do wonders to the entire building, whether its interiors or exteriors of the structure. But if you’re wondering where you can get expert-level wall painting services in Dubai- HomeRepairFix is the answer!",
			},
			upperRightDetail: {
				heading: "Book Painting Service",
				number: " 800 8289",
				details:
					"HomeRepairFix’ one of the many expertise is painting services which can be used for all your residential and commercial structures. They are currently the superior painting company in Dubai known majorly for handling different kinds of painting services for various types of infrastructures. With our skilled and efficient painters in Dubai, you can decorate or redecorate your homes, offices, or any other buildings you own. So get together with the HomeRepairFix painters and avail exquisite makeover to your building.",
			},
			midDetail: {
				heading:
					"Checkout HomeRepairFix Assorted Wall Painting Services in Dubai",
				video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
				details:
					"Our painting company has tremendously proficient painters ready to avail our various wall painting services along with other maintenance assistances. These include:",
			},
			lowerLeftDetail: {
				heading: "Interior decoration:",
				details:
					"We help you choose from a variety of paints to suit your interiors. Guiding our clients through some samples and offering them an idea of how their rooms will transform after painting, we ensure their content before going ahead with the actual paint job. Our painters also make sure that all your furniture, carpets and other items in the interior rooms of the building remain clean and undamaged.",
			},
			lowerCenterDetail: {
				heading: "Exterior painting:",
				details:
					"The outer look of the building is as important as inner. Hence, we cater to painting all kinds of walls (concrete, stone, brick or whatever), windows, doors, gates and such similar things. Our master painters will also advise you on the weather conditions and the paint that will perfectly suit the Dubai buildings. HomeRepairFix painters are always equipped with appropriate tools like ladders, ropes, paintbrushes and every other thing required to finish the job accurately.",
			},
			lowerRightDetail: {
				heading: "Maintenance works:",
				details:
					"Timely maintenance of the structures will make them look bright and also stand tall for decades. Hence, it’s not just the new building we work on. Both the new and old structures are tended to by our handyman crew equally. We offer house painting service, apartment painting services, commercial buildings and also villa painting services in Dubai among several other works. For the maintenance works of old buildings, we first patch up holes, cracks, and such breaks in the walls. Then we sand the wall properly to apply primer. Once this foundation has been laid, we move to avail our flawless wall painting services",
			},
		},
	},
	{
		name: "Deep Cleaning ",
		img: deepCleaning,
		description:
			"With the increase in weather temperatures everywhere, the last thing you want to deal with is air conditioner repair and maintenance to keep it running. Not everyone is...",
		vat: "17",
		price: "117",
		detail: {
			bannerImage: bannerImage,
			upperLeftDetail: {
				heading: "HomeRepairFix, Dubai's best painting service!",
				details:
					"The outer layer of the walls tends to get worn out due to rain, dust, accidental handprints, children’s drawings, furniture marks, stains and whatnot. This reduces the overall look and brightness of the house. Hence, buildings often require time to time painting job done to keep their charm up. A lick of paint can do wonders to the entire building, whether its interiors or exteriors of the structure. But if you’re wondering where you can get expert-level wall painting services in Dubai- HomeRepairFix is the answer!",
			},
			upperRightDetail: {
				heading: "Book Painting Service",
				number: " 800 8289",
				details:
					"HomeRepairFix’ one of the many expertise is painting services which can be used for all your residential and commercial structures. They are currently the superior painting company in Dubai known majorly for handling different kinds of painting services for various types of infrastructures. With our skilled and efficient painters in Dubai, you can decorate or redecorate your homes, offices, or any other buildings you own. So get together with the HomeRepairFix painters and avail exquisite makeover to your building.",
			},
			midDetail: {
				heading:
					"Checkout HomeRepairFix Assorted Wall Painting Services in Dubai",
				video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
				details:
					"Our painting company has tremendously proficient painters ready to avail our various wall painting services along with other maintenance assistances. These include:",
			},
			lowerLeftDetail: {
				heading: "Interior decoration:",
				details:
					"We help you choose from a variety of paints to suit your interiors. Guiding our clients through some samples and offering them an idea of how their rooms will transform after painting, we ensure their content before going ahead with the actual paint job. Our painters also make sure that all your furniture, carpets and other items in the interior rooms of the building remain clean and undamaged.",
			},
			lowerCenterDetail: {
				heading: "Exterior painting:",
				details:
					"The outer look of the building is as important as inner. Hence, we cater to painting all kinds of walls (concrete, stone, brick or whatever), windows, doors, gates and such similar things. Our master painters will also advise you on the weather conditions and the paint that will perfectly suit the Dubai buildings. HomeRepairFix painters are always equipped with appropriate tools like ladders, ropes, paintbrushes and every other thing required to finish the job accurately.",
			},
			lowerRightDetail: {
				heading: "Maintenance works:",
				details:
					"Timely maintenance of the structures will make them look bright and also stand tall for decades. Hence, it’s not just the new building we work on. Both the new and old structures are tended to by our handyman crew equally. We offer house painting service, apartment painting services, commercial buildings and also villa painting services in Dubai among several other works. For the maintenance works of old buildings, we first patch up holes, cracks, and such breaks in the walls. Then we sand the wall properly to apply primer. Once this foundation has been laid, we move to avail our flawless wall painting services",
			},
		},
	},
	{
		name: "Electrician",
		img: Electrician,
		description:
			"With the increase in weather temperatures everywhere, the last thing you want to deal with is air conditioner repair and maintenance to keep it running. Not everyone is...",
		vat: "19",
		price: "119",
		detail: {
			bannerImage: bannerImage,
			upperLeftDetail: {
				heading: "HomeRepairFix, Dubai's best painting service!",
				details:
					"The outer layer of the walls tends to get worn out due to rain, dust, accidental handprints, children’s drawings, furniture marks, stains and whatnot. This reduces the overall look and brightness of the house. Hence, buildings often require time to time painting job done to keep their charm up. A lick of paint can do wonders to the entire building, whether its interiors or exteriors of the structure. But if you’re wondering where you can get expert-level wall painting services in Dubai- HomeRepairFix is the answer!",
			},
			upperRightDetail: {
				heading: "Book Painting Service",
				number: " 800 8289",
				details:
					"HomeRepairFix’ one of the many expertise is painting services which can be used for all your residential and commercial structures. They are currently the superior painting company in Dubai known majorly for handling different kinds of painting services for various types of infrastructures. With our skilled and efficient painters in Dubai, you can decorate or redecorate your homes, offices, or any other buildings you own. So get together with the HomeRepairFix painters and avail exquisite makeover to your building.",
			},
			midDetail: {
				heading:
					"Checkout HomeRepairFix Assorted Wall Painting Services in Dubai",
				video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
				details:
					"Our painting company has tremendously proficient painters ready to avail our various wall painting services along with other maintenance assistances. These include:",
			},
			lowerLeftDetail: {
				heading: "Interior decoration:",
				details:
					"We help you choose from a variety of paints to suit your interiors. Guiding our clients through some samples and offering them an idea of how their rooms will transform after painting, we ensure their content before going ahead with the actual paint job. Our painters also make sure that all your furniture, carpets and other items in the interior rooms of the building remain clean and undamaged.",
			},
			lowerCenterDetail: {
				heading: "Exterior painting:",
				details:
					"The outer look of the building is as important as inner. Hence, we cater to painting all kinds of walls (concrete, stone, brick or whatever), windows, doors, gates and such similar things. Our master painters will also advise you on the weather conditions and the paint that will perfectly suit the Dubai buildings. HomeRepairFix painters are always equipped with appropriate tools like ladders, ropes, paintbrushes and every other thing required to finish the job accurately.",
			},
			lowerRightDetail: {
				heading: "Maintenance works:",
				details:
					"Timely maintenance of the structures will make them look bright and also stand tall for decades. Hence, it’s not just the new building we work on. Both the new and old structures are tended to by our handyman crew equally. We offer house painting service, apartment painting services, commercial buildings and also villa painting services in Dubai among several other works. For the maintenance works of old buildings, we first patch up holes, cracks, and such breaks in the walls. Then we sand the wall properly to apply primer. Once this foundation has been laid, we move to avail our flawless wall painting services",
			},
		},
	},
	{
		name: "Handy Man",
		img: Handyman,
		description:
			"With the increase in weather temperatures everywhere, the last thing you want to deal with is air conditioner repair and maintenance to keep it running. Not everyone is...",
		vat: "12",
		price: "112",
		detail: {
			bannerImage: bannerImage,
			upperLeftDetail: {
				heading: "HomeRepairFix, Dubai's best painting service!",
				details:
					"The outer layer of the walls tends to get worn out due to rain, dust, accidental handprints, children’s drawings, furniture marks, stains and whatnot. This reduces the overall look and brightness of the house. Hence, buildings often require time to time painting job done to keep their charm up. A lick of paint can do wonders to the entire building, whether its interiors or exteriors of the structure. But if you’re wondering where you can get expert-level wall painting services in Dubai- HomeRepairFix is the answer!",
			},
			upperRightDetail: {
				heading: "Book Painting Service",
				number: " 800 8289",
				details:
					"HomeRepairFix’ one of the many expertise is painting services which can be used for all your residential and commercial structures. They are currently the superior painting company in Dubai known majorly for handling different kinds of painting services for various types of infrastructures. With our skilled and efficient painters in Dubai, you can decorate or redecorate your homes, offices, or any other buildings you own. So get together with the HomeRepairFix painters and avail exquisite makeover to your building.",
			},
			midDetail: {
				heading:
					"Checkout HomeRepairFix Assorted Wall Painting Services in Dubai",
				video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
				details:
					"Our painting company has tremendously proficient painters ready to avail our various wall painting services along with other maintenance assistances. These include:",
			},
			lowerLeftDetail: {
				heading: "Interior decoration:",
				details:
					"We help you choose from a variety of paints to suit your interiors. Guiding our clients through some samples and offering them an idea of how their rooms will transform after painting, we ensure their content before going ahead with the actual paint job. Our painters also make sure that all your furniture, carpets and other items in the interior rooms of the building remain clean and undamaged.",
			},
			lowerCenterDetail: {
				heading: "Exterior painting:",
				details:
					"The outer look of the building is as important as inner. Hence, we cater to painting all kinds of walls (concrete, stone, brick or whatever), windows, doors, gates and such similar things. Our master painters will also advise you on the weather conditions and the paint that will perfectly suit the Dubai buildings. HomeRepairFix painters are always equipped with appropriate tools like ladders, ropes, paintbrushes and every other thing required to finish the job accurately.",
			},
			lowerRightDetail: {
				heading: "Maintenance works:",
				details:
					"Timely maintenance of the structures will make them look bright and also stand tall for decades. Hence, it’s not just the new building we work on. Both the new and old structures are tended to by our handyman crew equally. We offer house painting service, apartment painting services, commercial buildings and also villa painting services in Dubai among several other works. For the maintenance works of old buildings, we first patch up holes, cracks, and such breaks in the walls. Then we sand the wall properly to apply primer. Once this foundation has been laid, we move to avail our flawless wall painting services",
			},
		},
	},
	{
		name: "paintWork",
		img: paintWork,
		description:
			"With the increase in weather temperatures everywhere, the last thing you want to deal with is air conditioner repair and maintenance to keep it running. Not everyone is...",
		vat: "13",
		price: "113",
		detail: {
			bannerImage: bannerImage,
			upperLeftDetail: {
				heading: "HomeRepairFix, Dubai's best painting service!",
				details:
					"The outer layer of the walls tends to get worn out due to rain, dust, accidental handprints, children’s drawings, furniture marks, stains and whatnot. This reduces the overall look and brightness of the house. Hence, buildings often require time to time painting job done to keep their charm up. A lick of paint can do wonders to the entire building, whether its interiors or exteriors of the structure. But if you’re wondering where you can get expert-level wall painting services in Dubai- HomeRepairFix is the answer!",
			},
			upperRightDetail: {
				heading: "Book Painting Service",
				number: " 800 8289",
				details:
					"HomeRepairFix’ one of the many expertise is painting services which can be used for all your residential and commercial structures. They are currently the superior painting company in Dubai known majorly for handling different kinds of painting services for various types of infrastructures. With our skilled and efficient painters in Dubai, you can decorate or redecorate your homes, offices, or any other buildings you own. So get together with the HomeRepairFix painters and avail exquisite makeover to your building.",
			},
			midDetail: {
				heading:
					"Checkout HomeRepairFix Assorted Wall Painting Services in Dubai",
				video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
				details:
					"Our painting company has tremendously proficient painters ready to avail our various wall painting services along with other maintenance assistances. These include:",
			},
			lowerLeftDetail: {
				heading: "Interior decoration:",
				details:
					"We help you choose from a variety of paints to suit your interiors. Guiding our clients through some samples and offering them an idea of how their rooms will transform after painting, we ensure their content before going ahead with the actual paint job. Our painters also make sure that all your furniture, carpets and other items in the interior rooms of the building remain clean and undamaged.",
			},
			lowerCenterDetail: {
				heading: "Exterior painting:",
				details:
					"The outer look of the building is as important as inner. Hence, we cater to painting all kinds of walls (concrete, stone, brick or whatever), windows, doors, gates and such similar things. Our master painters will also advise you on the weather conditions and the paint that will perfectly suit the Dubai buildings. HomeRepairFix painters are always equipped with appropriate tools like ladders, ropes, paintbrushes and every other thing required to finish the job accurately.",
			},
			lowerRightDetail: {
				heading: "Maintenance works:",
				details:
					"Timely maintenance of the structures will make them look bright and also stand tall for decades. Hence, it’s not just the new building we work on. Both the new and old structures are tended to by our handyman crew equally. We offer house painting service, apartment painting services, commercial buildings and also villa painting services in Dubai among several other works. For the maintenance works of old buildings, we first patch up holes, cracks, and such breaks in the walls. Then we sand the wall properly to apply primer. Once this foundation has been laid, we move to avail our flawless wall painting services",
			},
		},
	},
	{
		name: "plumbing",
		img: plumbing,
		description:
			"With the increase in weather temperatures everywhere, the last thing you want to deal with is air conditioner repair and maintenance to keep it running. Not everyone is...",
		vat: "14",
		price: "114",
		detail: {
			bannerImage: bannerImage,
			upperLeftDetail: {
				heading: "HomeRepairFix, Dubai's best painting service!",
				details:
					"The outer layer of the walls tends to get worn out due to rain, dust, accidental handprints, children’s drawings, furniture marks, stains and whatnot. This reduces the overall look and brightness of the house. Hence, buildings often require time to time painting job done to keep their charm up. A lick of paint can do wonders to the entire building, whether its interiors or exteriors of the structure. But if you’re wondering where you can get expert-level wall painting services in Dubai- HomeRepairFix is the answer!",
			},
			upperRightDetail: {
				heading: "Book Painting Service",
				number: " 800 8289",
				details:
					"HomeRepairFix’ one of the many expertise is painting services which can be used for all your residential and commercial structures. They are currently the superior painting company in Dubai known majorly for handling different kinds of painting services for various types of infrastructures. With our skilled and efficient painters in Dubai, you can decorate or redecorate your homes, offices, or any other buildings you own. So get together with the HomeRepairFix painters and avail exquisite makeover to your building.",
			},
			midDetail: {
				heading:
					"Checkout HomeRepairFix Assorted Wall Painting Services in Dubai",
				video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
				details:
					"Our painting company has tremendously proficient painters ready to avail our various wall painting services along with other maintenance assistances. These include:",
			},
			lowerLeftDetail: {
				heading: "Interior decoration:",
				details:
					"We help you choose from a variety of paints to suit your interiors. Guiding our clients through some samples and offering them an idea of how their rooms will transform after painting, we ensure their content before going ahead with the actual paint job. Our painters also make sure that all your furniture, carpets and other items in the interior rooms of the building remain clean and undamaged.",
			},
			lowerCenterDetail: {
				heading: "Exterior painting:",
				details:
					"The outer look of the building is as important as inner. Hence, we cater to painting all kinds of walls (concrete, stone, brick or whatever), windows, doors, gates and such similar things. Our master painters will also advise you on the weather conditions and the paint that will perfectly suit the Dubai buildings. HomeRepairFix painters are always equipped with appropriate tools like ladders, ropes, paintbrushes and every other thing required to finish the job accurately.",
			},
			lowerRightDetail: {
				heading: "Maintenance works:",
				details:
					"Timely maintenance of the structures will make them look bright and also stand tall for decades. Hence, it’s not just the new building we work on. Both the new and old structures are tended to by our handyman crew equally. We offer house painting service, apartment painting services, commercial buildings and also villa painting services in Dubai among several other works. For the maintenance works of old buildings, we first patch up holes, cracks, and such breaks in the walls. Then we sand the wall properly to apply primer. Once this foundation has been laid, we move to avail our flawless wall painting services",
			},
		},
	},
];
export let AreaArray = [
	{ name: "Abu Hail" },
	{ name: "Al Awir First" },
	{ name: "Al Awir Second" },
	{ name: "Al Bada" },
	{ name: "Al Baraha" },
	{ name: "Al Barsha First" },
	{ name: "Al Barsha Second" },
	{ name: "Al Barsha South First" },
	{ name: "Al Barsha South Second" },
	{ name: "Al Barsha South Third" },
	{ name: "Al Barsha Third" },
	{ name: "Al Buteen" },
	{ name: "Al Dhagaya" },
	{ name: "Al Garhoud" },
	{ name: "Al Guoz Fourth" },
	{ name: "Al Hamriya, Dubai" },
	{ name: "Al Hamriya Port" },
	{ name: "Al Hudaiba" },
	{ name: "Al Jaddaf" },
	{ name: "Al Jafiliya" },
	{ name: "Al Karama" },
	{ name: "Al Khabisi" },
	{ name: "Al Khwaneej First" },
	{ name: "Al Khwaneej Second" },
	{ name: "Al Kifaf" },
	{ name: "Al Mamzar" },
	{ name: "Al Manara" },
	{ name: "Al Merkad" },
	{ name: "Al Mina" },
	{ name: "Al Mizhar First" },
	{ name: "Al Mizhar Second" },
	{ name: "Al Muraqqabat" },
	{ name: "Al Murar" },
	{ name: "Al Sabkha" },
	{ name: "Al Muteena" },
	{ name: "Al Nahda First" },
	{ name: "Al Nahda Second" },
	{ name: "Al Quoz First" },
	{ name: "Al Quoz Industrial First" },
	{ name: "Al Quoz Industrial Fourth" },
	{ name: "Al Quoz Industrial Second" },
	{ name: "Al Quoz Industrial Third" },
	{ name: "Al Quoz Second" },
	{ name: "Al Quoz Third" },
	{ name: "Al Qusais First" },
	{ name: "Al Qusais Industrial Fifth" },
	{ name: "Al Qusais Industrial First" },
	{ name: "Al Qusais Industrial Fourth" },
	{ name: "Al Qusais Industrial Second" },
	{ name: "Al Qusais Industrial Third" },
	{ name: "Al Qusais Second" },
	{ name: "Al Qusais Third" },
	{ name: "Al Raffa" },
	{ name: "Al Ras" },
	{ name: "Al Rashidiya" },
	{ name: "Al Rigga" },
	{ name: "Al Safa First" },
	{ name: "Al Safa Second" },
	{ name: "Al Safouh First" },
	{ name: "Al Safouh Second" },
	{ name: "Al Satwa" },
	{ name: "Al Shindagha" },
	{ name: "Al Souq Al Kabeer" },
	{ name: "Al Twar First" },
	{ name: "Al Twar Second" },
	{ name: "Al Twar Third" },
	{ name: "Al Warqa'a Fifth" },
	{ name: "Al Warqa'a First" },
	{ name: "Al Warqa'a Fourth" },
	{ name: "Al Warqa'a Second" },
	{ name: "Al Warqa'a Third" },
	{ name: "Al Wasl" },
	{ name: "Al Waheda" },
	{ name: "Ayal Nasir" },
	{ name: "Aleyas" },
	{ name: "Bu Kadra" },
	{ name: "Dubai Investment park First" },
	{ name: "Dubai Investment Park Second" },
	{ name: "Emirates Hill First" },
	{ name: "Emirates Hill Second" },
	{ name: "Emirates Hill Third" },
	{ name: "Hatta" },
	{ name: "Hor Al Anz" },
	{ name: "Hor Al Anz East" },
	{ name: "Jebel Ali 1" },
	{ name: "Jebel Ali 2" },
	{ name: "Jebel Ali Industrial" },
	{ name: "Jebel Ali Palm" },
	{ name: "Jumeira First" },
	{ name: "Palm Jumeira" },
	{ name: "Jumeira Second" },
	{ name: "Jumeira Third" },
	{ name: "Al Mankhool" },
	{ name: "Marsa Dubai" },
	{ name: "Mirdif" },
	{ name: "Muhaisanah Fourth" },
	{ name: "Muhaisanah Second" },
	{ name: "Muhaisanah Third" },
	{ name: "Muhaisnah First" },
	{ name: "Al Mushrif" },
	{ name: "Nad Al Hammar" },
	{ name: "Nadd Al Shiba Fourth" },
	{ name: "Nadd Al Shiba Second" },
	{ name: "Nadd Al Shiba Third" },
	{ name: "Nad Shamma" },
	{ name: "Naif" },
	{ name: "Al Muteena First" },
	{ name: "Al Muteena Second" },
	{ name: "Al Nasr, Dubai" },
	{ name: "Port Saeed" },
	{ name: "Arabian Ranches" },
	{ name: "Ras Al Khor" },
	{ name: "Ras Al Khor Industrial First" },
	{ name: "Ras Al Khor Industrial Second" },
	{ name: "Ras Al Khor Industrial Third" },
	{ name: "Rigga Al Buteen" },
	{ name: "Trade Centre 1" },
	{ name: "Trade Centre 2" },
	{ name: "Umm Al Sheif" },
	{ name: "Umm Hurair First" },
	{ name: "Umm Hurair Second" },
	{ name: "Umm Ramool" },
	{ name: "Umm Suqeim First" },
	{ name: "Umm Suqeim Second" },
	{ name: "Umm Suqeim Third" },
	{ name: "Wadi Alamardi" },
	{ name: "Warsan First" },
	{ name: "Warsan Second" },
	{ name: "Za'abeel First" },
	{ name: "Za'abeel Second" },
];
export let HoursData = [1, 2, 3, 4, 5, 6, 7, 8];
